let routes = [

    {
        path: "/auth/",
        props:true,
        component: () => import("@/views/auth/main.vue"),
        children: [
          {
            path: "login",
            name: "login",
            props:true,
            component: () => import("@/views/auth/login.vue"),
            meta: {
             title: 'Login to Your Dashboard'
            }
          },

          {
            path: "forgot-password",
            name: "forgot_password",
            props:true,
            component: () => import("@/views/auth/forgot_password.vue"),
            meta: {
             title: 'Forgot Password'
            }
          },

          {
            path: "reset-password",
            name: "reset_password",
            props:true,
            component: () => import("@/views/auth/reset_password.vue"),
            meta: {
             title: 'Reset Password'
            }
          },
        ]
    },


    {
      path: "/register",
      name: "register",
      props:true,
      component: () => import("@/views/auth/register_agent.vue"),
      meta: {
        title: 'Register Agent Account'
      }
    },


]
export default routes;