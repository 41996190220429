import axios from "axios"
import Nprogress from 'nprogress'
import store from '@/store'
import 'nprogress/nprogress.css';


window.axios = axios
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://resttheewell.deepcodegroup.com/api/'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common.crossDomain = true;

axios.interceptors.request.use(function(config) {
  Nprogress.done()
  if(store.getters.authuser){
    let token = localStorage.getItem('resttheewellagent'+ store.getters.authuser.id)
    config.headers['Authorization'] = `Bearer ${token}`;
  }else{
    config.headers['Authorization'] = `Bearer ${''}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
  Nprogress.done()
}) 

  axios.interceptors.request.use(config =>{
    Nprogress.start()
    return config;
  })

  axios.interceptors.request.use(response =>{
    Nprogress.done()
    return response;
  })