import store from '@/store'
const adminguard = (to, from, next) => {
  if(localStorage.getItem('resttheewellagent' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.role == 'admin'){
      return next()
  }else{
      return next({ name: 'login' })
  }
};

let routes = [
  {
    path: "/",
    props: true,
    beforeEnter: adminguard,
    component: () => import("@/views/app/main.vue"),
    title: "",

    children: [
      {
        path: "/",
        name: "welcome",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/dash.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },

      //settings
      {
        path: "/settings/locations",
        name: "locations",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/settings/locations.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
     


      //obituries
      {
        path: "/draft-obtuaries",
        name: "draft_obtuaries",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/obtuary/draft_obtuaries.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      {
        path: "/closed-obtuaries",
        name: "closed_obtuaries",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/obtuary/closed.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },

      {
        path: "/pending-obtuaries",
        name: "pending_obtuaries",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/obtuary/pending.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },

      {
        path: "/live-obtuaries",
        name: "live_obtuaries",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/obtuary/approved.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },

 
      {
        path: "/obituary/update/:id",
        name: "updateobituarydetails",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/obtuary/updateobituary.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },

      {
        path: "/obitaury-details/:id",
        name: "obitaury_details",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/obtuary/obituary_details.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },




      //agents
      {
        path: "/agents",
        name: "agents",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/agents/index.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
     
      {
        path: "/condolense",
        name: "condolense",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/condolenses/index.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      {
        path: "/tributes",
        name: "tributes",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/tributes/index.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
    
      {
        path: "/users",
        name: "users",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/users/index.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      {
        path: "/tributes-review",
        name: "tributes_review",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/contacts.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      {
        path: "/contacts",
        name: "contacts",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/contacts.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
    ]
  }
    
];
export default routes;
